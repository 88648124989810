import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import vLogo from '../assets/images/vLogo.png'
import EmployeeDoc from '../components/employee-doc'
import 'bootstrap/dist/css/bootstrap.css';



const EmployeePortal = () => {
  const [show, setShow] = useState(true)
  const [password, setPassword] = useState('')
  const [isError, setIsError] = useState(false)

  const masterPassword = 'Vtech2020!'

  const validate = (password) => {
    password === masterPassword ? setShow(false)
      : setIsError(true)
  }

  const DocList = props => {
    const { data } = props
    const string = JSON.stringify(data.allContentfulEmployeeDocs['edges'])
    const docs = data.allContentfulEmployeeDocs.edges
    
    return (
      docs.map(doc => {
        return (
          <EmployeeDoc title={doc.node.file.title} url={doc.node.file.file.url} />
        )
      })

    )
  }

  return (
  <React.Fragment>
    <Modal style={{ textAlign: 'center' }} id="portal-password-form" show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <Row style={{ height: '100vh' }}>
            <Col></Col>
            <Col className='my-auto'>
              <img style={{ margin: '40px auto', display: 'block' }} src={vLogo} />
              <div id="password-form">
                <label style={{ margin: 'auto', display: 'block' }} for="password">
                  <input onChange={() => setPassword(event.target.value)} placeholder="Password" type="password" name="password" id="password" />
                </label>
                <button onClick={() => validate(password)}>LOGIN</button>
                <p class="error" style={isError ? { display: 'block' } : { display: 'none' }}> Your password is incorrect.</p>
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Container>
        <Row className="space">
          <Col>
            <h2 className="green-headline" style={{ textAlign: 'center', marginBottom: '50px' }}>V-Tech Employee Documents</h2>
            <StaticQuery
              query={graphql`
               query PortalQuery {
               allContentfulEmployeeDocs(filter: {node_locale: {eq: "en-US"}}) {
                edges {
                  node {
                    file {
                      file {
                         url
                      }
                      title
                    }
                  }
                }
              }
            }
           `
          }
          render={data => < DocList data={data} />}
         />
        </Col>
      </Row>
    </Container>
  </React.Fragment>  
    )
}

export default EmployeePortal
