import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import src from '../assets/images/vLogo.png'
import externalLink from '../assets/images/external-link.png'

const EmployeeDoc = props => {
    const { title, url } = props

    return(
        <Row className="employee-doc">
            <Col md="2">
              <img src={src}/>
            </Col>
            <Col md="10" className="my-auto">
              <a href={url} target="_blank">{title} <img className="external-link-icon" src={externalLink}/> </a>  

            </Col>
        </Row>
    )

}

export default EmployeeDoc

